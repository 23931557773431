//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contactsStore } from '@/store'
import { TdBtn } from 'td-ui'

export default {
  name: 'TabContactsImport',
  components: {
    'step-1': () => import('./Step1'),
    'step-2': () => import('./Step2'),
    'step-3': () => import('./Step3'),
    DirectImportStepper: () => import('./DirectImportStepper'),
    TdBtn,
    ExampleBlock: () => import('./ExampleBlock.vue'),
    IconArrowLeft: () => import('@tada/icons/dist/IconArrowLeftM.vue'),
    ContactsImportProgress: () => import('../ContactsImportProgress.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      contactsStep: 1,
      parsedContacts: [],
      selectedContacts: [],
      importedContacts: [],
      contactsImportError: null,
      currentContactsImportUID: null,
      steps: [{
        number: 1,
        title: 'Загрузка',
      },
      {
        number: 2,
        title: 'Проверка',
      },
      {
        number: 3,
        title: 'Импорт данных',
      },
      {
        number: 4,
        title: 'Готово',
      }],
      stopUploadingFlag: false,
    }
  },
  watch: {
    selectedContacts () {
      this.$emit('input', this.selectedContacts)
    },
  },
  computed: {
    noContactsFound () {
      return this.parsedContacts.length <= 0
    },
    importedContactsCount () {
      return this.importedContacts.filter(contact => contact.imported).length
    },
    step2ActionLabel () {
      let contactDeclension = ''
      if (this.selectedContacts?.length > 4 || this.selectedContacts?.length === 0) { contactDeclension = this.$t('glossary.contactsR') } else if (this.selectedContacts?.length > 1) { contactDeclension = this.$t('glossary.contactR') } else contactDeclension = this.$t('glossary.contact')
      return `${this.$t('modals.AddContact.directImport.step1.action', { number: this.selectedContacts?.length })} ${contactDeclension}`
    },
  },
  methods: {
    handleGoBack () {
      this.contactsStep--
      this.selectedContacts = []
    },
    onContactsParse (parsedContacts) {
      this.parsedContacts = parsedContacts
    },
    onContactsSelect (selectedContacts) {
      this.selectedContacts = selectedContacts
    },
    async importContacts () {
      for (let i = 0; i < this.importedContacts.length; i++) {
        await contactsStore.actions.importContacts(this.importedContacts)
        this.importedContacts[i].imported = true
      }
    },
    handleParsedContactsCheck () {
      this.contactsStep++
    },
    handleContactsImport () {
      this.importedContacts = JSON.parse(JSON.stringify(this.selectedContacts))
      this.contactsStep++
      this.importContacts().then(() => {
        this.contactsStep++
      })
    },
  },
}
